import Home from './pages/Home.jsx';
import "./styles/App.css";

function App() {
  return (
    <div className='main'><Home/></div>
   
  );
}

export default App;
