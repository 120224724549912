import React, { useEffect, useState } from 'react'
import NavBar from '../components/NavBar.jsx'
import "../styles/Home.css";
import landingPage from "../assets/LandingPage_V1-01.png"
import btn from "../assets/LandingPage_V1-02.png"
import landingPageMobile from "../assets/LandingPage_V1-03.png"

export default function Home() {
    // const [loading, setLoading] = useState(false);

    // useEffect
    return (
        <div className='homeBody'>
            <div className='landingPage'>
                <img src={window.innerWidth >= 769 ? landingPage : landingPageMobile} alt='landingPage' className='landingImg' />
            </div>
            <div className='btn'>
                <a href='https://docs.google.com/presentation/d/1w_oQ7WIqQBYB6GhCv07E94chnbmQezB76Ik0ZAabhCY/edit#slide=id.g2efcda454f7_0_0'><img src={btn} alt='btn' /></a>

            </div>
        </div>
    )
}
